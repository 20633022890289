import { BaseModel } from '../core/base/base-model';
import { Hotline } from './Hotline';

export const EVENT_TYPE = {
  "000002": "Thoại",
  "000001": "SUB",
};

export const DESTINATION_TYPE = {
  1: "Viettel Nội mạng",
  2: "Viettel Ngoại mạng",
  3: "VNPT nội mạng",
  4: "VNPT ngoại mạng",
  5: "Mobi nội mạng",
  6: "Mobi ngoại mạng",
  7: "VNM nội mạng",
  8: "VNM ngoại mạng",
  9: "VNPT gọi Viettel",
  10: " Gmobile nội mạng",
  11: "Gmobile ngoại mạng",
  12: "Cố định nội hạt",
  15: "Cố định ngoại mạng",
  16: "Cố định C gọi di động",
  17: "Cố định C gọi cố định",
  18: "Cố định D gọi di động",
  19: "Cố định D gọi cố định",
  20: "Cố định G gọi di đông",
  21: "Cố định G gọi cố định",
  22: "Viettel _KH nội mạng",
  23: "Viettel_KH  ngoại mạng"
};


export class SipTrunkCallLog extends BaseModel {
  id: string;
  created_at: Date;
  updated_at: Date;
  shop_id: string;
  hotline_id: string;
  hotline: Hotline;
  ref_id: string;
  event_type: string;
  event_type_display: string;
  event_source: string;
  event_id: string;
  charge_session_id: string;
  display_num: string;
  called_num: string;
  hotline_num: string;
  enterprise_num: string;
  event_occur_time: string;
  charge_time: string | Date;
  amount: Number;
  count: Number;
  total_count: Number;
  total_amount: Number;
  charge_status: Number;
  charge_result: string;
  charge_description: string;
  description: string;
  direction_type: string;
  destination_type: string;
  destination_type_display: string;
  account_id: string | Number;
  retry_times: string | Number;
  insert_time: string | Date;
  retry_after: string | Date;
  cus_id: Number;

  static callLogMap(callLog: SipTrunkCallLog): SipTrunkCallLog {
    callLog.event_type_display = EVENT_TYPE[callLog.event_type]
    callLog.destination_type_display = DESTINATION_TYPE[callLog.destination_type]
    return callLog;
  }
}

