import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'apps/core/src/services/user.service';
import {AuthenticateService, AuthenticateStore} from '@etop/core';
import * as validatecontraints from 'apps/core/src/services/validation-contraints.service';
import { UtilService } from 'apps/core/src/services/util.service';
import { CommonUsecase } from 'apps/shared/src/usecases/common.usecase.service';
import { ActivatedRoute } from '@angular/router';
import { AccountApi, ShopAccountAPI } from '@etop/api';
import { ModalController } from 'apps/core/src/components/modal-controller/modal-controller.service';
import { ConfigService } from '@etop/core/services/config.service';
import { StringHandler } from '@etop/utils';
import { UserSource } from '@etop/models';
import { ForgotPasswordComponent } from '../../../components/forgot-password/forgot-password.component';


enum View {
  NONE = 'none',
  PHONE_SUBMIT = 'phonesubmit',
  PHONE_VERIFY = 'phoneverify',
  REGISTER = 'register',
  LOGIN = 'login',
  CREATE_SHOP = 'createshop'
}

@Component({
  selector: 'etelecom-cs-desktop-register',
  templateUrl: './desktop-register.component.html',
  styleUrls: ['./desktop-register.component.scss']
})
export class DesktopRegisterComponent implements OnInit {

  login: string;
  password: string;
  verify_code: string;
  email: string;
  fullname: string;
  e_aff: string;

  view = View;
  _currentView: View = View.NONE;
  error = false;
  errorMessage = '';

  loading = false;

  countdown = 60;

  signupData: any = {
    agree_email_info: true,
    agree_tos: false,
    ref_aff:'',
  };
  session: any;
  onFocusEmail = false;
  loadingView = false;
  userName: string;
  source: UserSource = 'telecom';

  showPassword = false;


  constructor(
    private util: UtilService,
    private commonUsecase: CommonUsecase,
    private userService: UserService,
    private auth: AuthenticateStore,
    private activatedRoute: ActivatedRoute,
    private modalController: ModalController,
    private config: ConfigService,
    private etopAccountApi: AccountApi,
  ) {

  }

  async ngOnInit() {
    this._currentView = View.LOGIN;
  }

  getAssets() {
    return this.config.get('assets')
  }

  getAppName() {
    return this.config.get('app')?.appName
  }

  allowRegister() {
    return !!this.config.get('allow_register')
  }

  policyUrl() {
    return this.config.get('policy_url')
  }

  focusEmail() {
    this.onFocusEmail = true;
  }

  onBlurEmail() {
    this.onFocusEmail = false;
  }

  async onLogin() {
    if (!this.signupData.phone || !this.password) {
      toastr.error('Vui lòng nhập đầy đủ thông tin đăng nhập để tiếp tục.');
      return;
    }
    this.signupData.phone = this.signupData.phone.replace(/\s/g, "");
    this.loading = true;
    try {
      let res = await this.commonUsecase.login({
        login: this.signupData.phone,
        password: this.password
      });
      if ( res == "create_shop" ) {
        this.userName = this.auth.snapshot.user.full_name;
        this._currentView = View.CREATE_SHOP;
      }
    } catch (e) {
      toastr.error(this.util.replacePartnerMailSupport(e?.message || e?.msg), 'Đăng nhập thất bại!');
    }
    this.loading = false;
  }

  forgotPassword() {
    const modal = this.modalController.create({
      component: ForgotPasswordComponent
    });
    modal.show().then();
    modal.onDismiss().then(() => {
    });
  }

  async signUp() {
    this.loading = true;
    const signupData = this.signupData;
    this.error = false;
    let phone = signupData.phone;
    let email = signupData.email;

    try {
      if (!signupData.full_name) {
        throw new Error('Vui lòng nhập Tên đầy đủ.');
      }

      if(!signupData.shop_name) {
        throw new Error('Vui lòng nhập Tên công ty đầy đủ.');
      }

      phone = (phone && phone.split(/-[0-9a-zA-Z]+-test/)[0]) || '';
      phone = (phone && phone.split('-test')[0]) || '';

      if (!phone || !phone.match(/^[0-9]{9,10}$/)) {
        throw new Error('Số điện thoại không đúng');
      }

      email = (email && email.split(/-[0-9a-zA-Z]+-test/)[0]) || '';
      email = email.split('-test')[0];

      if (!validatecontraints.EmailValidates[0].check(email)) {
        throw new Error('Địa chỉ email không hợp lệ.');
      }
      if (signupData.password !== signupData.confirm) {
        throw new Error('Mật khẩu nhập lại không chính xác.');
      }
      signupData.ref_aff = this.e_aff;
      await this.commonUsecase.register(signupData, this.source);
      this.loading = false;
    } catch (e) {
      this.error = true;
      this.errorMessage = e.message;
      toastr.error(this.util.replacePartnerMailSupport(e?.message || e?.msg), 'Đăng ký thất bại.');
      this.loading = false;
      return;
    }
  }

  async createShop(){
    const signupData = this.signupData;
    if(!signupData.shop_name) {
      toastr.error('Vui lòng nhập Tên công ty đầy đủ.');
      return;
    }
    this.loading = true;
    try{
      const registerShopRequest: ShopAccountAPI.RegisterShopRequest = {
        name: signupData.shop_name,
        phone: this.auth.snapshot.user.phone
      }
      const res = await this.userService.registerShop(registerShopRequest);
      const account = await this.userService.switchAccount(res.shop.id);
      await this.etopAccountApi.updateURLSlug({
        account_id: res.shop.id,
        url_slug: StringHandler.createHandle(res.shop.name + "-" + res.shop.code)
      }, account.access_token);
      await this.commonUsecase.updateSessionInfo(true);
      await this.commonUsecase.login({
        login: this.signupData.phone,
        password: this.password
      });
      this.loading = false;
    } catch (e) {
      toastr.error(this.util.replacePartnerMailSupport(e?.message || e?.msg), 'Tạo công ty thất bại!');
    }

  }

  get isMobile() {
    return this.util.isMobile;
  }

  async onPhoneVerify() {
    this.loading = true;
    try {
      let res = await this.userService.verifyPhoneUsingToken(this.verify_code);
      if (res.code == 'fail') {
        toastr.error(res.msg);
      } else {
        toastr.success('Số điện thoại đã được xác nhận thành công.');
        this._currentView = View.REGISTER;
      }
    } catch (e) {
      toastr.error(this.util.replacePartnerMailSupport(e?.message || e?.msg), 'Xác thực thất bại!');
    }
    this.loading = false;
  }

  async onPhoneSubmit() {
    this.loading = true;
    this.signupData.phone = this.signupData.phone.replace(/\s/g, "");
    try {
      if (!this.signupData.phone) {
        this.loading = false;
        return toastr.error('Vui lòng nhập số điện thoại.');
      }
      let isTest = this.signupData.phone.split(/-[0-9a-zA-Z]+-test$/).length > 1;
      if (isTest || this.util.validatePhoneNumber(this.signupData.phone)) {
        await this.checkUserRegistration(this.signupData.phone);

        if (this._currentView == View.PHONE_VERIFY) {
          this.verify_code = '';
          this.countTimeVerify();
        }
      }
    } catch (e) {
      toastr.error('Có lỗi xảy ra. Vui lòng bấm F5 để load lại trang và thử lại!');
    }
    this.loading = false;
  }

  countTimeVerify() {
    this.countdown = 60;
    let interval = setInterval(() => {
      if (this.countdown < 1) {
        clearInterval(interval);
      } else {
        this.countdown -= 1;
      }
    }, 1000);
  }

  togglePassword() {
    this.showPassword = !this.showPassword
    let elementPass = <HTMLInputElement>document.querySelector('#password');
    
    if (this.showPassword) {
      elementPass.type = 'text';
    } else {
      elementPass.type = 'password';
    }
  }

  currentView(view: View) {
    return this._currentView == view;
  }

  toView(view: View) {
    this._currentView = view;
  }

  async checkUserRegistration(phone) {
    this.loading = true;
    try {
      let captcha = await AuthenticateService.getReCaptcha(this.config.get('recaptcha_key'));
      let data = {
        phone: phone,
        recaptcha_token: captcha
      };
      let check = await this.userService.checkUserRegistration(data);
      if (check.exists) {
        this._currentView = View.LOGIN;
        this.loading = false;
        return;
      }
      this.session = await this.userService.initSession();
      this.auth.updateToken(this.session.access_token);
      await this.userService.sendPhoneVerification(phone);
      this._currentView = View.PHONE_VERIFY;
    } catch (e) {
      toastr.error(this.util.replacePartnerMailSupport(e?.message || e?.msg));
    }
    this.loading = false;
  }

  async reSendVerifyPhone() {
    await this.userService.sendPhoneVerification(this.signupData.phone);
    this.countTimeVerify();
  }
}
