import { Injectable } from '@angular/core';
import { CommonUsecase } from 'apps/shared/src/usecases/common.usecase.service';
import { AuthenticateStore } from '@etop/core';
import { Router } from '@angular/router';
import { UserService } from 'apps/core/src/services/user.service';
import { Account, ExtendedAccount } from 'libs/models/Account';
import { AccountApi, UserApi } from '@etop/api';
import { StringHandler } from '@etop/utils';
import { TelegramService } from '@etop/features';
import { ShopSettingsService } from '@etop/features/services/shop-settings.service';
import { LocationService } from '@etop/state/location';
import { UtilService } from 'apps/core/src/services/util.service';

@Injectable()
export class ETelecomCommonUsecase extends CommonUsecase {
  full_name;
  password;
  email;
  confirm;
  error = false;

  signupData: any = {};

  loading = false;

  provinces;

  constructor(
    private userService: UserService,
    private userApi: UserApi,
    private router: Router,
    private auth: AuthenticateStore,
    private telegramService: TelegramService,
    private etopAccountApi: AccountApi,
    private shopSettingsService: ShopSettingsService,
    private locationService: LocationService,
    private util: UtilService,
  ) {
    super();
    (window as any).injectToken = (token) => {
      this.auth.updateToken(token);
      this.checkAuthorization(true).then(() =>
        setTimeout(() => {
          location.href = location.origin;
        }, 500)
      );
    };
  }

  async checkAuthorization(fetchAccount = false) {
    if (!this.auth.snapshot?.token) return
    const ref = this.router.url.split('?')[0];
    try {
      const route = window.location.pathname + window.location.search;
      this.auth.setRef(route);
      await this.updateSessionInfo(fetchAccount);
      const pathname = this.router.url.split('?')[0];
      if (pathname.startsWith('/s/')) {
        const index = pathname.split('/')[2];
        this.auth.selectAccount(index);
      } else {
        this.auth.selectAccount(null);
      }

      await this.navigateAfterAuthorized();
    } catch (e) {
      debug.error('ERROR in checkAuthorization', e);
      this.auth.clear();
      this.auth.setRef(ref);
    }
  }

  async navigateAfterAuthorized() {
    let ref = this.auth.getRef(true).replace('/', '');
    const defaultRef = 'settings';
    if (ref == 'login' || ref == 'register') {
      await this.router.navigate([`./${defaultRef}`]);
    } else {
      await this.router.navigateByUrl(
        ref || `/s/${this.auth.currentAccountIndex()}/settings`
      );
    }
  }

  async login(data: { login: string; password: string }) {
    try {
      const res = await this.userApi.login({
        login: data.login,
        password: data.password,
        account_type: 'shop',
      });

      this.auth.updateToken(res.access_token);
      this.auth.updateUser(res.user);
      this.locationService.initLocations().then();
      return await this.setupAndRedirect();
    } catch (e) {
      toastr.error(this.util.replacePartnerMailSupport(e?.message || e?.msg), 'Đăng nhập thất bại!');
    }
  }

  async register(data: any, source = '') {
    const res = await this.userService.signUp({ ...data, source });
    this.signupData = Object.assign({}, data, res.user);
    toastr.success('Đăng ký thành công!');

    const loginRes = await this.userApi.login({
      login: data.phone,
      password: data.password,
      account_type: 'shop',
    });

    this.auth.updateToken(loginRes.access_token);
    this.auth.updateUser(res.user);
    const shopRes = await this.userService.registerShop(
      new ExtendedAccount({
        phone: data.phone,
        name: data.shop_name,
      })
    );
    const account = await this.userService.switchAccount(shopRes.shop.id);
    await this.etopAccountApi.updateURLSlug(
      {
        account_id: shopRes.shop.id,
        url_slug: StringHandler.createHandle(
          shopRes.shop.name + '-' + shopRes.shop.code
        ),
      },
      account.access_token
    );
    this.auth.updateToken(account.access_token);
    this.signupData = Object.assign({}, this.signupData, shopRes);
    this.telegramService.newAccountTelecom(this.signupData);
    this.setupAndRedirect().then();
    this.locationService.initLocations().then();
  }

  async setupAndRedirect() {
    await this.updateSessionInfo(true);
    this.auth.selectAccount(null);
    if (!this.auth.snapshot.shop) {
      return 'create_shop';
    }
    const ref = this.auth.getRef(true);
    this.router.navigateByUrl(
        `/s/${this.auth.currentAccountIndex()}/${ref || 'settings'}`
      ).then();
  }

  async updateSessionInfo(fetchAccounts = false) {
    const res = await this.userService.checkToken(this.auth.snapshot.token);
    let { access_token, account, shop, user, available_accounts } = res;
    const shop_accounts = available_accounts
      .filter((a) => a.type === 'shop')
      .sort((a, b) => a.id > b.id);

    const no_init_shop = !shop;

    if (!shop && available_accounts && available_accounts.length) {
      shop = shop_accounts[0];
      account = shop_accounts[0];
    }
    const accounts: Account[] = fetchAccounts
      ? await Promise.all(
          shop_accounts.map(async (a, index) => {
            const accRes = await this.userService.switchAccount(a.id);
            a.token = accRes.access_token;
            a.shop = accRes.shop;
            a.id = accRes.shop && accRes.shop.id;
            a.image_url = a.shop.image_url;
            a.display_name = `${a.shop.code} - ${a.shop.name}`;
            a.permission = accRes.account.user_account.permission;
            return new Account(a);
          })
        )
      : this.auth.snapshot.accounts;

    if (accounts.length > 0) {
      this.auth.updateInfo({
        token: (no_init_shop && accounts[0].token) || access_token,
        account: {
          ...account,
          ...shop,
          display_name: `${shop.code} - ${shop.name}`,
        },
        accounts,
        shop,
        user,
        permission: account.user_account.permission,
        isAuthenticated: true,
        uptodate: true,
      });
      this.shopSettingsService.getSetting().then((setting) => {
        this.auth.updateShopSetting(setting);
      });
    }
  }

  async redirectIfAuthenticated(): Promise<any> {
    return this.checkAuthorization(true);
  }
}
