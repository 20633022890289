import { BaseModel } from  '../core/base/base-model';
import { Connection } from './Connection';
import { User } from './User';

export const HOTLINE_STATUS = {
  Z: "Chưa kích hoạt",
  P: "Đã kích hoạt",
  unknown: "Không xác định"
};

export enum MobileNetwork {
  unknown = 'unknown',
  mobifone = 'mobifone',
  vinaphone = 'vinaphone',
  viettel = 'viettel',
  other = 'other',
  vietnamobile = 'vietnamobile',
  gmobile = 'gmobile',
  dongduongtelecom = 'dongduongtelecom',
  virtual = 'virtual',
  fixed = 'fixed'
}

export const MobileNetworkDisplay = {
  unknown: 'Không xác định',
  mobifone: 'MobiFone',
  vinaphone: 'Vinaphone',
  viettel: 'Viettel',
  other: 'Khác',
  vietnamobile: 'Vietnamobile',
  gmobile: 'Gmobile',
  dongduongtelecom: 'Đông Dương Telecom',
  virtual: 'Số ảo',
  fixed: 'Số cố định'
}

export const MobileNetworkOptions = [
  {
    name: 'Viettel',
    value: MobileNetwork.viettel
  },
  {
    name: 'Vinaphone',
    value: MobileNetwork.vinaphone
  },
  {
    name: 'MobiFone',
    value: MobileNetwork.mobifone
  },
  {
    name: 'Vietnamobile',
    value: MobileNetwork.vietnamobile
  },
  {
    name: 'Gmobile',
    value: MobileNetwork.gmobile
  },
  {
    name: 'Đông Dương Telecom',
    value: MobileNetwork.dongduongtelecom
  },
  {
    name: 'Số ảo',
    value: MobileNetwork.virtual
  },
  {
    name: 'Số cố định',
    value: MobileNetwork.fixed
  },
  {
    name: 'Không xác định',
    value: MobileNetwork.unknown
  }
]
export class Hotline extends BaseModel{
  connection_id: string;
  connection_method: string;
  created_at: Date;
  description: string;
  hotline: string;
  id: string;
  name: string;
  network: MobileNetwork;
  owner_id: string;
  updated_at: Date;
  status: string;
  is_free_charge: boolean;
  status_display: string;
  user: User;
  connection_name?: string;
  connection?: Connection;
  telco_destination?: string;
  is_trial?: boolean;
  network_display?: string;
  
  static hotlineMap(hotline: Hotline): Hotline {
    hotline = new Hotline(hotline);
    hotline.status_display = HOTLINE_STATUS[hotline.status];
    return hotline;
  }
}
